// 
// widgets.scss
//


// Inbox-widget(Used Profile)
.inbox-widget {
    .inbox-item {
        overflow: hidden;
        padding: 12px 0px;
        position: relative;
        border-bottom: 1px solid $gray-300;

        .inbox-item-img {
            display: block;
            float: left;
            margin-right: 15px;

            img {
                width: 40px;
            }
        }

        .inbox-item-text {
            color: $gray-500;
            display: block;
            margin: 0;
            overflow: hidden;
        }

        .inbox-item-date {
            color: $gray-600;
            font-size: 0.6875rem;
            position: absolute;
            right: 5px;
            top: 10px;
        }
    }
}