// 
// timeline.scss
//

.timeline {
  margin-bottom: 50px;
  position: relative;
  &:before {
      background-color: $gray-300;
      bottom: 0;
      content: "";
      left: 50%;
      position: absolute;
      top: 30px;
      width: 2px;
      z-index: 0;
  }
  .time-show {
      margin-bottom: 30px;
      margin-top: 30px;
      position: relative;
  }
  .timeline-box {
      background: $card-bg;
      display: block;
      margin: 15px 0;
      position: relative;
      padding: 20px;
      border-radius: 4px;
      border: 1px solid darken($card-bg, 4%);
      box-shadow: $box-shadow-sm;
  }
  .timeline-album {
      margin-top: 12px;
      a {
          display: inline-block;
          margin-right: 5px;
      }
      img {
          height: 36px;
          width: auto;
          border-radius: 3px;
      }
  }
}

@include media-breakpoint-up(md) {
  .timeline {
      .time-show {
          margin-right: -69px;
          text-align: right;
      }
      .timeline-box {
          margin-left: 45px;
      }
      .timeline-icon {
          background: darken($gray-300, 4%);
          border-radius: 50%;
          display: block;
          height: 20px;
          left: -54px;
          margin-top: -10px;
          position: absolute;
          text-align: center;
          top: 50%;
          width: 20px;
          i {
              color: $gray-600;
              font-size: 13px;
              position: absolute;
              left: 4px;
              margin-top: 1px;
          }
      }
      .timeline-desk {
          display: table-cell;
          vertical-align: top;
          width: 50%;
      }
  }
  .timeline-item {
      display: table-row;
      &:before {
          content: "";
          display: block;
          width: 50%;
      }
      .timeline-desk {
          .arrow {
              border-bottom: 12px solid transparent;
              border-right: 12px solid $card-bg !important;
              border-top: 12px solid transparent;
              display: block;
              height: 0;
              left: -12px;
              margin-top: -12px;
              position: absolute;
              top: 50%;
              width: 0;
              &::before{
                content: "";
                border-bottom: 12px solid transparent;
                border-right: 12px solid darken($gray-300, 4%) !important;
                border-top: 12px solid transparent;
                display: block;
                height: 0;
                left: -1px;
                margin-top: -12px;
                position: absolute;
                top: 50%;
                width: 0;
                z-index: -1;
              }
          }
      }
      &.timeline-item-left {
          &:after {
              content: "";
              display: block;
              width: 50%;
          }
          .timeline-desk {
              .arrow-alt {
                  border-bottom: 12px solid transparent;
                  border-left: 12px solid $card-bg !important;
                  border-top: 12px solid transparent;
                  display: block;
                  height: 0;
                  left: auto;
                  margin-top: -12px;
                  position: absolute;
                  right: -12px;
                  top: 50%;
                  width: 0;
                  &::before{
                    content: "";
                    border-bottom: 12px solid transparent;
                    border-left: 12px solid darken($gray-300, 4%) !important;
                    border-top: 12px solid transparent;
                    display: block;
                    height: 0;
                    left: auto;
                    margin-top: -12px;
                    position: absolute;
                    right: -1px;
                    top: 50%;
                    width: 0;
                    z-index: -1;
                  }
              }
              .album {
                  float: right;
                  margin-top: 20px;
                  a {
                      float: right;
                      margin-left: 5px;
                  }
              }
          }
          .timeline-icon {
              left: auto;
              right: -56px;
          }
          &:before {
              display: none;
          }
          .timeline-box {
              margin-right: 45px;
              margin-left: 0;
              text-align: right;
          }
      }
  }
}

@include media-breakpoint-down(sm) {
  .timeline {
      .time-show {
          text-align: center;
          position: relative;
      }
      .timeline-icon {
          display: none;
      }
  }
}