// 
// error.scss
//

// Error text with shadow
.text-error {
    color: $primary;
    font-size: 98px;
    line-height: 150px;
    text-shadow: rgba($primary, 0.3) 5px 1px;
    font-weight: $font-weight-semibold;
}
