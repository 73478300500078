// 
// page-title.scss
//

.page-title-box {
    position: relative;
    padding: 0 30px;
    margin: 0 -30px;
    .page-title {
        font-size: 18px;
        margin: 0;
        line-height: 75px;
    }

    // .page-title-right {
    //     float: right;
    //     margin-top: 7px;
    // }

    .nav-pills-custom {
        .nav-item{
          .nav-link{
              padding: 5px 18px;
              border-radius: 20px;
              color: $primary;
              line-height: 28px;
            &:focus, &:hover{
              background: $card-bg;
            }
            &.active{
                background-color: $card-bg;
                color: $primary;
              }
          }
        }
    }

      
    .page-title-btn, .page-title-nav-pills  {
        margin-top: 18px;
    }
}


@include media-breakpoint-down(sm) {
    .page-title-box {
        .page-title {
            display: block;
            white-space: nowrap;
            text-overflow: ellipsis;
            overflow: hidden;
            line-height: 70px;
        }
        .page-title-btn {
            display: none;
        }
    }
}
